<template>
  <a-modal
    title="新增"
    :width="700"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 消息标题 -->
            <a-form-item label="消息标题" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input 
                placeholder="请输入"
                v-decorator="['title', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
            <!-- 用户类型 -->
            <a-form-item label="用户类型" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-select 
                v-decorator="['userType', { rules: [{ required: true, message: '请输入！' }] }]"
                allow-clear 
                placeholder="请选择"
                @change="()=>{this.searchList=[]; form.setFieldsValue({userId:undefined, messageType:undefined}); }"
              >
                <a-select-option :value="1">用户</a-select-option>
                <a-select-option :value="2">商家</a-select-option>
                <a-select-option :value="3">全部</a-select-option>
              </a-select>
            </a-form-item>
            <!-- 消息类型 -->
            <a-form-item 
              v-show="form.getFieldValue('userType')!=3" 
              label="消息类型" 
              :labelCol="labelCol" 
              :wrapperCol="wrapperCol" 
              has-feedback
            >
              <a-select 
                v-decorator="['messageType', { rules: [{ required: form.getFieldValue('userType')==3?false:true, message: '请输入！' }] }]"
                allow-clear
                placeholder="请选择"
                @change="()=>{this.searchList=[];  form.setFieldsValue({userId:undefined})}"
              >
                <a-select-option :value="1">群体</a-select-option>
                <a-select-option :value="2">个人</a-select-option>
              </a-select>
            </a-form-item>
            <!-- 个人输入搜索框 -->
            <a-form-item 
              v-show="form.getFieldValue('messageType')==2" 
              label="搜索" :labelCol="labelCol" 
              :wrapperCol="wrapperCol" 
              has-feedback
            >
              <a-select
                show-search
                v-decorator="['userId', { rules: [{ required: (form.getFieldValue('userType')==3 || form.getFieldValue('messageType')!=2) ? false : true, message: '请搜索昵称或手机号！' }] }]"
                placeholder="请搜索昵称或手机号"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @search="handleSearch"
              >
                <a-select-option
                  v-for="item in searchList"
                  :key="item.id"
                  :value="item.id"
                >{{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
            <!-- 是否发布 -->
            <a-form-item label="是否发布" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-select
               v-decorator="['hasRelease', { rules: [{ required: true, message: '请输入！' }] }]"
               allow-clear 
               placeholder="请选择"
              >
                <a-select-option value="1">不发布</a-select-option>
                <a-select-option value="2">发布</a-select-option>
              </a-select>
            </a-form-item>
            <!-- 消息内容 -->
            <a-form-item label="消息内容" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-textarea
                v-decorator="['content', { rules: [{ required: true, message: '请输入！' }] }]"
                placeholder="请输入消息内容"
              />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { 
  messageAdd, 
  customList,
  storeList
} from '@/api/modular/mallLiving/message'

export default {
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      pictureLoading:false,
      picture:'',
      searchList:[] //搜索框的数组
    }
  },

  methods: {
    // 初始化方法
    add() {
      this.visible = true
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        //如果用户类型为全部，则消息类型自动为群体
        if(values.userType==3){
          values.messageType = 1
        }
        if (!errors) {
            messageAdd(values).then((res) => {
              if(res.success){
                this.$message.success("新增成功！")
                this.$emit("ok",values)
                this.confirmLoading = false
                this.handleCancel()
              }else{
                setTimeout(()=>{
                  this.confirmLoading = false
                }, 600)
              }
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleSearch(value){
      // 搜索用户
      if(this.form.getFieldValue('userType')==1){
        customList({keywords: value}).then((res)=>{
          if(res.success){
            this.searchList = res.data.map((item)=>{
              return {
                id:item.id,
                name:item.nickname
              }
            })
          }
        })
      }
      // 搜索商家
      if(this.form.getFieldValue('userType')==2){
        storeList({keywords: value}).then((res)=>{
          if(res.success){
            this.searchList = res.data.map((item)=>{
              return {
                id:item.id,
                name:item.nickname
              }
            })
          }
        })
      }
    },
    handleChange(){

    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    }
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>