<template>
    <a-modal
        title="详情"
        width='50%'
        :visible="visible"
        :destroyOnClose="true"
        @cancel="handleCancel"
        :footer="null"
    >
        <a-spin :spinning="confirmLoading">
            <a-row>
                <a-form layout="inline">
                    <a-col>
                        <!-- 第1行详情 -->
                        <a-row>
                            <a-col :md="24" :sm="24">
                                <a-form-item  label="创建时间">
                                    <span>{{details.createTime}}</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第2行详情 -->
                        <a-row>
                            <a-col :md="24" :sm="24">
                                <a-form-item  label="消息标题">
                                    <span>{{details.title}}</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第2行详情 -->
                        <a-row>
                            <a-col :md="24" :sm="24">
                                <a-form-item  label="消息内容">
                                    <div v-html="details.content">{{details.content}}</div>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-form>
            </a-row>
        </a-spin>
    </a-modal>
</template>

<script>
import { 
  messageDetail
} from '@/api/modular/mallLiving/message'

export default {
    data(){
        return {
            visible: false, //modal框显示状态
            confirmLoading: false,
            id:'',
            details:{},
            form: this.$form.createForm(this),
        }
    },
    methods:{
        //初始化方法
        detail(record){
            this.visible = true
            this.confirmLoading = true

            //获取详情数据
            setTimeout(()=>{
                messageDetail({id: record.id}).then((res)=>{
                    if(res.success){
                        this.details = res.data
                        this.confirmLoading = false
                    }
                }).finally((res)=>{
                    setTimeout(()=>{
                        this.confirmLoading = false
                    },5000)
                })
            },100)
            
        },
        // 点击遮罩层或右上角叉或取消按钮的操作
        handleCancel(){
            this.visible = false
            this.confirmLoading = false
            setTimeout(()=>{
                this.details = {} //关闭之后清空
            },100)
        }
    }
}
</script>
<style lang="less" scoped>
    .mytable{
        margin-bottom:70px;
        border-collapse:collapse;
        width:100% ;
        height:250px;
        .title {
            background: rgb(207, 248, 248);
            width:20%
        }
        td{
            border:2px solid rgb(228, 225, 225);
            padding:7px;
            font-size:15px;
            width:30%;
        }
    }
    img{
        width:80px;
        height: 50px;
    }
</style>