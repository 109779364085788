//系统消息接口
import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-02-15
 */

//系统消息分页查询
export function messagePageList(parameter){
    return axios({
        url: '/appMessage/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//系统消息新增
export function messageAdd(parameter){
    return axios({
        url: '/appMessage/add',
        method: 'post',
        data: parameter 
    })
}
//系统消息编辑
export function messageEdit(parameter){
    return axios({
        url: '/appMessage/edit',
        method: 'post',
        data: parameter 
    })
}
//系统消息详情
export function messageDetail(parameter){
    return axios({
        url: '/appMessage/detail',
        method: 'post',
        params: parameter 
    })
}
//系统消息删除
export function messageDelete(parameter){
    return axios({
        url: '/appMessage/delete',
        method: 'post',
        data: parameter 
    })
}
//系统消息发布
export function messagePush(parameter){
    return axios({
        url: '/appMessage/publish',
        method: 'post',
        params: parameter 
    })
}


//查询用户集合
export function customList(parameter){
    return axios({
        url: '/customer/list',
        method: 'post',
        params: parameter 
    })
}
//查询商家集合
export function storeList(parameter){
    return axios({
        url: '/store/list',
        method: 'post',
        params: parameter 
    })
}

